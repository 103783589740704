<template>
  <div class="home">
    <div class="head">
      <div class="title" >
        阜平制梁场智慧管理中心
      </div>
    </div>
    <div class="maib-body">
      <div class="main-left">
        <div class="left-1">
            <div class="info">
             <img src="@/assets/info.jpg" alt="" style="width: 100%;height: 100%;">
            </div>
        </div> 
        <div class="left-2">
          <div class="sub-title">智能喷淋养护</div>
          <div class="line-1">
            <Temperature/>
          </div>
          <div class="line-2">
            <Humidness/>
          </div>
        </div> 
      </div>
      <div class="main-center">
        <div class="cente1">
          <div class="canvas">
            <div class="loading" v-if="loading">
              <img src="@/assets/loading.gif" alt="">
              <div class="load-txt">模型加载中...</div>
            </div>
            <Canvas3d @clickModel="clickModelHandel" @loaded="loading=false"/>
          </div>
        </div>
        <div class="cente2">
          <div class="sub-title">生产物资管理</div>
          <div>
            <Material/>
          </div>
        </div>
      </div>
      <div class="main-right">
        <div class="right-1">
          <div class="sub-title">梁统计分析</div>
          <div>
            <Weather></Weather>
          </div>
        </div> 
        <div class="right-2">
          <div class="sub-title">台座统计分析</div>
          <div>
            <Plan/>
          </div>
        </div> 
      </div>
    </div>
    <Dialog :md-show="showDialog"></Dialog>
    <Dialog :mdShow="mdShow" @close="mdShow=false">
      <img src="@/assets/guan1.png" class="guan" alt="" @click="clickguan1">
      <img src="@/assets/guan2.png" class="guan" alt="" @click="clickguan2">
    </Dialog>
    <Dialog :mdShow="pdfDiaShow" @close="pdfDiaShow=false">
      <iframe :src="pdfUrl" frameborder="0"></iframe>
    </Dialog>
    
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Charts from './Charts.vue'
import Canvas3d from './components/Canvas3d.vue';
import Temperature from './components/Temperature.vue';
import Humidness from './components/Humidness.vue';
import Weather from './Weather.vue';
import Material from './components/Material.vue';
import Plan from './components/Plan.vue';
import Dialog from './components/Dialog.vue';
import request from '@/api/request'

export default {
  name: 'HomeView',
  components: {
    HelloWorld, Charts, Canvas3d, Weather,Temperature,Humidness,Material,Plan,Dialog
  },
  data(){
    return {
      showDialog:false,
      loading:true,
      mdShow:false,
      pdfDiaShow:false,
      fileData:'',
      pdfUrl:''
    }
  },
  mounted(){
    this.fetchData();
  },
  methods:{
    fetchData(){
      let _this = this
        request({ url: 'filedata', method: 'get' }).then(res => {
            _this.fileData = res.data
        });
    },
    clickguan1(){
      this.pdfUrl = this.fileData.guan1.fullPath
      this.pdfDiaShow = true
    },
    clickguan2(){
      this.pdfUrl = this.fileData.guan2.fullPath
      this.pdfDiaShow = true
    },
    clickModelHandel(e){
      e = e.replace(/\s+/g,"");
      console.log(e,'clickmodel')
      if(e=='live'){
        this.pdfDiaShow=true;
        this.pdfUrl = this.fileData.live.fullPath
      }
      if(e=='cangku'){
        this.pdfDiaShow=true;
        this.pdfUrl = this.fileData.cangku.fullPath
      }
      if(e=='gangjin'){
        this.pdfDiaShow=true;
        this.pdfUrl = this.fileData.gangjin.fullPath
      }
      if(e=='cunliangqu'){
        this.pdfDiaShow=true;
        this.pdfUrl = this.fileData.cunliangqu.fullPath
      } 
      if(e=='jiaobanzhan'){
        window.open(this.fileData.jiaobanzhan.url,'_blank')
      }
      if(e=='penlin'){
        window.open(this.fileData.penlin.url,'_blank')
      }
      if(e=='shiyanshi'){
        window.open(this.fileData.shiyanshi.url,'_blank')
      }
      if(e=='yangchen'){
        window.open(this.fileData.yangchen.url,'_blank')
      }
      if(e=='menwei'){
        this.pdfDiaShow=true;
        this.pdfUrl = this.fileData.menwei.fullPath
      }
      if(e=='jiankong1'){
        window.open(this.fileData.jiankong1.url,'_blank')
      }
      if(e=='jiankong2'){
        window.open(this.fileData.jiankong2.url,'_blank')
      }
      if(e=='xiangmuguanli'){
        window.open(this.fileData.xiangmuguanli.url,'_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
 .guan{
  cursor: pointer;
 }
.home {
  background: url('~@/assets/main-bg.jpg') no-repeat 100% 100%/100% 100%;
  width: 100vw;
  min-width: 1440px;
  min-height: 900px;
  height: 100vh;
  overflow: hidden;
  position: relative;

  

  .head {
    width: 100%;
    height: 85px;
    background: url('~@/assets/head-bg.png') no-repeat 100% 100%/100% 100%;

    .title {
      font-size: 30px;
      font-weight: bold;
      color: #fff;
      line-height: 85px;
      height: 85px;
      text-shadow: 0 0 10px #31a8ec;
    }
  }
  .sub-title{
    color: #fff;
    width: calc(100% - 10px);
    margin: 5px auto;
    text-shadow: 0 0 10px #31a8ec;
    padding: 3px 0;
    background-image: linear-gradient(to right, rgba(0,255,255,0.8),rgba(0,255,255,0.1));
  }
  .maib-body{
    width: 100%;
    height: calc(100vh - 85px);
    display: flex;
    justify-content: space-between;
    padding: 20px;
    .main-right,.main-left{
      width: 24%;
      height: 200px;
    }
    .main-center{
      width: 49%;
      height: 200px;
      .cente1{
        width: 100%;
        height: 500px;
        background: url('~@/assets/cente1.png') no-repeat 100% 100%/100% 100%;
        padding: 60px 20px 0px;
        .canvas{
          width: 100%;
          height: 430px;
          position: relative;
          background: url('~@/assets/canvasbg.png') no-repeat 100% 100%/100% 100%;
          .loading{
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba(1,1,1);
            border-radius: 10px;
            img{
              height: 90%;
            }
            .load-txt{
              color: #00ffff;
              text-shadow: 0 0 10px #ffffff;
            }
          }
        }
      }
      .cente2{
        margin-top: 5px;
        width: 100%;
        height: 300px;
        background: url('~@/assets/cente2.png') no-repeat 100% 100%/100% 100%;
        padding-top: 1px;
      }
    }
  }
  .left-1{
    width: 100%;
    height: 260px;
    background: url('~@/assets/pannel1.png') no-repeat 100% 100%/100% 100%;
    padding-top: 1px;
    .info{
      width: 95%;
      margin: 10px auto;
      overflow-y: auto;
      color: #fff;
      text-align: left;
      height: 240px;
    }
  }
  .left-2{
    width: 100%;
    height: 530px;
    background: url('~@/assets/pannel2.png') no-repeat 100% 100%/100% 100%;
    padding-top: 1px;
    margin-top: 15px;
    .line-1,.line-2{
      width: 100%;
      height: 240px;
    }
  }
  .right-1{
    width: 100%;
    height: 260px;
    background: url('~@/assets/pannel1.png') no-repeat 100% 100%/100% 100%;
    padding-top: 1px;
  }
  .right-2{
    width: 100%;
    height: 530px;
    background: url('~@/assets/pannel2.png') no-repeat 100% 100%/100% 100%;
    padding-top: 1px;
    margin-top: 15px;
  }
  .footer {
    position: absolute;
    width: 100%;
    padding-right: 15px;
    bottom: 0;
    box-sizing: border-box;
  }

  .weather {
    position: absolute;
    right: 30px;
    top: 50px;
  }
}
</style>
