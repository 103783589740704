<template>
    <div class="chart-wraper">
        <div class="graph-item">
            <div class="graph" ref="chartColumn1"></div>

            <div class="graph-title">
                人员出勤率（%）
            </div>
        </div>
        <div class="graph-item">
            <div class="graph" ref="chartColumn2"></div>

            <div class="graph-title">
                物资使用
            </div>
        </div>
        <div class="graph-item">
            <div class="graph" ref="chartColumn3"></div>

            <div class="graph-title">
                特种设备预警
            </div>
        </div>
       
        <!-- <div ref="chartColumn1" style="width:160px; height:150px;"></div>
            <div ref="chartColumn2" style="width:160px; height:150px;"></div>
            <div ref="chartColumn3" style="width:160px; height:150px;"></div>
            <div ref="chartColumn4" style="width:160px; height:150px;"></div> -->
    </div>
</template>
   
<script>
import request from '@/api/request'
export default {
    name: 'Material',
    data() {
        return {
            chartData: '',
            charName: [],
            usedArr: [],
            option1: {
                grid: {
                    top: '10%',
                    left: '10%',
                    right: '10%',
                    bottom: '10%',
                },

                color: ['#00ffff', '#00a0e9', '#ea68a2', '#df7153', '#fad83a', '#c490bf', '#1fe15f', '#3087d6', '#4be1ff'],
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: {
                    type: 'category',
                    data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                    axisLine: {
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    axisTick:{
                        show:false
                    }
                },
                series: [
                    {
                        data: [12, 33, 55, 33, 42, 65, 78, 55, 33, 42, 65, 78],
                        type: 'line',

                    }
                ]
            },
            option2: {
                tooltip: {
                    trigger: 'item'
                },
                color: ['#00ffff', '#00a0e9', '#ea68a2', '#df7153', '#fad83a', '#c490bf', '#1fe15f', '#3087d6', '#4be1ff'],
                legend: {
                    left: 'center',
                    textStyle: {
                        color: '#fff'
                    },
                    itemWidth: 15,
                    itemHeight:5
                },
                series: [
                    {
                        name: '物资统计',
                        type: 'pie',
                        radius: ['45%', '65%'],
                        center: ['50%', '65%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 20,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            
                        ]
                    }
                ]
            },
            option3: {
                title: {
                    text: '2023年',
                    textStyle:{
                        color:'#fff'
                    }
                },
                color:['#4be1ff'],
                tooltip: {
                    trigger: 'axis',

                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                    axisLine: {
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    interval:0
                    
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    axisTick:{
                        show:false
                    }
                },
                series: [
                    {
                        name: '设备预警次数',
                        type: 'bar',

                        data: [10, 17, 140, 120, 30, 0, 120, 30,17, 140, 11,55]
                    },

                ]
            },
            
        }
    },

    methods: {

        fetchData() {
            let _this = this;
            request({ url: 'material', method: 'get' }).then(res => {

            });
        },
        initChart(data) {
            console.log(data)
            let _this = this
            this.option1.series[0].data = data.attendance
            
            this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1)
            this.chartColumn1.setOption(this.option1)

            data.material.forEach(v => {
                _this.option2.series[0].data.push({value:v.used,name:v.txt})
            });
            this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2)
            this.chartColumn2.setOption(this.option2)

            this.option3.series[0].data = data.device
            this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3)
            this.chartColumn3.setOption(this.option3)
            
        }
    },
    mounted: function () {
        let _this = this
        request({ url: 'material', method: 'get' }).then(res => {
            _this.chartData = res.data
            _this.initChart(res.data)
        });

    }
}
</script>
   
<style lang="scss" scoped>
.title {
    color: #fff;
    line-height: 25px;
    height: 25px;
    text-align: left;
    text-shadow: 0 0 10px #31a8ec;

    &:before {
        content: '';
        margin-right: 5px;
        vertical-align: middle;
        display: inline-block;
        border-radius: 5px;
        width: 10px;
        height: 25px;
        background-color: #11dbee;
    }
}

.chart-wraper {
    width: 100%;

    padding: 10px;
    padding-top: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;

    .graph-item {
        width: 33%;
        height: 225px;
        position: relative;

        .graph {
            width: 100%;
            height: 200px;
        }

        .graph-title {
            color: #fff;
        }

        .graph-detail {
            position: absolute;
            font-size: 22px;
            width: 100%;
            top: 35%;
        }
    }
}
</style>