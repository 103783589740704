<template>
    <div ref="chartColumn" style="width:100%; height:100%;"></div>
</template>
   
<script>
import request from '@/api/request'
export default {
    
    data() {
        return {
            option: {
                title: {
                    text: '温度分析',
                    left:'5%',
                    textStyle:{
                        color:'#fff',
                        fontSize:'15'
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['环境测点', '梁体测点'],
                    textStyle:{
                        color:'#05defa'
                    }
                },
                grid:{
                    left:'10%',
                    top:'20%',
                    bottom:'10%',
                    right:'10%'
                },
                color:['#429cfe','#0ff00f'],
                xAxis: {
                    axisLine:{
                        lineStyle:{
                            color:'#fff'
                        }
                        
                    },
                    data: ['1', '2', '3', '4', '5', '6','7','8','9','10']
                },
                yAxis: {
                    axisLine:{
                        lineStyle:{
                            color:'#fff'
                        }
                        
                    },
                },
                series: [
                    {
                        name: '环境测点',
                        type: 'bar',
                        barWidth:'40',
                        data: [5, 20, 36, 1, 10, 20]
                    },
                    {
                        name: '梁体测点',
                        type: 'line',
                        itemStyle:{
                            color:'orange'
                        },
                        barWidth:'40',
                        data: [15, 10, 16, 20, 10, 20]
                    },
                ]
            }
        }
    },
    methods: {
        fetchData() {
           
        },

        initChart(data) {
            this.option.xAxis.data=data.time
            this.option.series[0].data=data.env
            this.option.series[1].data=data.body
            this.chartColumn = this.$echarts.init(this.$refs.chartColumn)
            this.chartColumn.setOption(this.option)
        }
    },
    mounted: function () {
        let _this = this;
        request({ url: 'temperature', method: 'get' }).then(res => {
            _this.initChart(res.data)
        });
        
    }
}
</script>
   
<style lang="scss" scoped>
.title {
    color: #fff;
    line-height: 25px;
    height: 25px;
    text-align: left;
    text-shadow: 0 0 10px #31a8ec;
    &:before {
        content: '';
        margin-right: 5px;
        vertical-align: middle;
        display: inline-block;
        border-radius: 5px;
        width: 10px;
        height: 25px;
        background-color: #11dbee;
    }
}
.chart-wraper{
    background:url('~@/assets/panel-bg-lg.png') no-repeat 100% 100%/100% 100%;
    padding: 10px;
    padding-top: 30px;
    box-sizing: border-box;
}

</style>