<template>
    <div>
        <div class="md-modal" :class="{ 'md-show': mdShow }">
            <div class="md-content">
                <div class="btn-close" @click="$emit('close')"><span>X</span></div>
                <div style="clear: both;"></div>
                <div class="md-body">
                    
                    <slot></slot>
                </div>
            </div>
        </div>
        <div class="md-overlay"></div>
    </div>
</template>

<script>
export default {
    name: 'Dialog',
    props:{
        mdShow:{
            type:Boolean,
            default:false
        }
    }
}
</script>

<style lang="scss" scoped>

.md-overlay{
  position: fixed;
    width: 100%;
    height: 100%;
    visibility: hidden; 
    top: 0;
    left: 0;
    z-index: 9999995;
    opacity: 0;
    background: rgba(1,1,1,0.3);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}
.md-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    
    // width: 50%;
    // max-width: 550px;
    min-width: 320px;
    
    height: auto;
    z-index: 9999999;
    perspective: 1300px;
    visibility: hidden;
    transform: translateX(-50%) translateY(-50%);
    .md-content{
      transform-style: preserve-3d;
      transform: rotateX(-70deg);
      transition: all 0.3s;
      opacity: 0;
      color: #5b5b5b;
      background: rgba(1,1,1,0.6);
      border-radius: 10px;
      position: relative;
      margin: 0 auto;
      min-height: 250px;
      padding: 0 10px 10px;
      .md-body{
        margin-top: 5px;
      }
    }
}
iframe{
    width: 1200px;
    height: 650px;
    ::-webkit-scrollbar{
      display: none;
    }
}
.md-show{
  visibility: visible;
  &~.md-overlay{
    opacity: 1;
    visibility: visible;
  }
  .md-content{
    transform: rotateX(0deg);
    opacity: 1
  }
}
.btn-close{
    color: #fff;
    float: right;
    margin-right: 15px;
    margin-top: 10px;
    cursor: pointer;
}
</style>