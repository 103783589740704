<template>
    <div id="canvas3d" style="height: 100%;width: 100%;"></div>
</template>
   
<script>
import * as THREE from 'three'
import * as dat from 'dat.gui'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import {DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
export default {
    name: 'Canvas3d',
    data() {
        return {
            render: null,
            scene: null,
            camera: null,
            clock: null,
            controls: null,
            ahelper: null,
            mixer: null,
            animations:[]
        }
    },
    methods: {
        initThree() {
            this.initRender();
            this.initCamera();
            this.initScene();
            // this.initAxesHelper();
            this.initLight();
            this.initControls();
            this.loadModel(); 
            this.renderDom();
        },
        initRender() {
            let dom = document.getElementById("canvas3d");
            let Width = dom.clientWidth
            let Height = dom.clientHeight
            const render = new THREE.WebGLRenderer({
                antialias: true,
                logarithmicDepthBuffer: true,
                alpha: true,
            })
            render.setClearAlpha(0.0)
            render.setSize(Width, Height)
            render.setClearColor(0x000000, 0)
            render.toneMapping = THREE.ACESFilmicToneMapping;
            render.toneMappingExposure = 1;
            // render.outputEncoding = THREE.SRGBColorSpace
            this.render = render;
        },
        initScene() {
            this.scene = new THREE.Scene();
        },
        initCamera() {
            let dom = document.getElementById("canvas3d");
            let Width = dom.clientWidth
            let Height = dom.clientHeight
            console.log(Width, Height)
            const camera = new THREE.PerspectiveCamera(75, Width / Height, 0.1, 1000);
            camera.position.set(2, 4,4)
            camera.lookAt(0,0,0)
            this.camera = camera;
            let _this = this;
            //创建射线对象
            let raycaster = new THREE.Raycaster();
            //创建用于保存鼠标点击位置的二维向量
            let mousePoint = new THREE.Vector2();
            const container = document.getElementById('canvas3d')
            let timer;
            this.render.domElement.addEventListener('pointerdown',function(){
                timer = new Date().getTime();
            })
            this.render.domElement.addEventListener('pointerup', (e) => {
                let now =new Date().getTime()
                console.log(now - timer);
                if((now-timer)>200){
                    return;
                }
                let getBoundingClientRect = container.getBoundingClientRect()

                let px = getBoundingClientRect.left
                let py = getBoundingClientRect.top
                let x = e.clientX;
                let y = e.clientY;
                mousePoint.x = ((x-px) / container.offsetWidth) * 2 - 1;
                mousePoint.y = -((y-py) / container.offsetHeight) * 2 + 1;

                /* 算法公式:
                   鼠标点击位置x/页面宽度 = x轴比例
                   x轴比例 * 2 -1 = 坐标系扩大两倍,且坐标系x轴0点向右移动100%
                   y轴计算要麻烦一点,html的y轴从左上角开始计算,所以我们先计算比例,然后扩大坐标系
                   然后再将当前坐标系*-1,可以让坐标系反向,原先y轴的正方向变成了由屏幕下方到屏幕上方
                   坐标系转负后,这里还需要加一个1来让坐标系放到屏幕中心
                   最终得到的结果是,在以屏幕正中心为坐标系原点的,x,y位置
                */

                //将射线绑定到相机上,并通过mousePoint定位到相机的指定位置
                raycaster.setFromCamera(mousePoint, camera);

                //拾取物体
                let intersects = raycaster.intersectObjects(this.scene.children);
                
                if (intersects.length > 0) {
                    let intersect = intersects[0];
                    console.log(intersect);
                    let object = intersect.object;
                   _this.$emit('clickModel',object.name)
                }
            });
        },
        initControls() {
            this.clock = new THREE.Clock();
            this.controls = new OrbitControls(this.camera, this.render.domElement);
            this.controls.enableDamping = true
            this.controls.maxPolarAngle = Math.PI*2/5
            this.controls.minDistance = 1
            this.controls.maxDistance = 7
            this.controls.listenToKeyEvents( this.render.domElement)
            
        },
        initLight() {
            let ligth1 = new THREE.AmbientLight(0xffffff, 1)
            ligth1.position.set(0, 15, 0)
            this.scene.add(ligth1)
        },
        initAxesHelper() {
            let axesHelper = new THREE.AxesHelper(30)
            this.scene.add(axesHelper)
        },
        loadModel() {
            let _this = this
            let loader = new GLTFLoader()
            let dracoLoader = new DRACOLoader()
            dracoLoader.setDecoderPath('/assets/draco/gltf/');
            dracoLoader.setDecoderConfig({type:'js'})
            dracoLoader.preload();
            loader.setDRACOLoader(dracoLoader)
            loader.load(
                '/assets/models/gongchang/gongchang.gltf',
                function (gltf) {
                    gltf.scene.traverse(function (child) {
                        if (child.isMesh) {
                            // console.log(child.material)
                            child.material.emissive = child.material.color
                            child.material.emissiveMap = child.material.map
                            // child.material = new THREE.MeshLambertMaterial({
                            //     map: child.material.map, //获取原来材质的颜色贴图属性值
                            //     opacity:child.material.opacity,
                            //     alpha:true
                            // })
                        }
                    })
                    _this.mixer = new THREE.AnimationMixer(gltf.scene)
                    gltf.animations.map(item => {
                        _this.mixer.clipAction(item).loop = THREE.LoopRepeat
                        _this.animations.push(_this.mixer.clipAction(item))
                    })
                    
                    _this.animations.map(item => {
                        item.play()
                    })
                    // gltf.scene.scale.set(0.05,0.05,0.05)
                    _this.scene.add(gltf.scene)
                    _this.$emit('loaded')
                },function(xhr){
                    console.log(xhr.loaded / xhr.total)
                }
            );
        },
        renderDom() {
            let _this = this
            const delta = this.clock.getDelta() //获取自上次调用的时间差
            if(_this.mixer){
                // _this.mixer.update(delta)
            }
            _this.controls.update(delta)
            document.getElementById("canvas3d").appendChild(this.render.domElement);
            _this.render.render(_this.scene, _this.camera)
            requestAnimationFrame(_this.renderDom)
        }
    },
    mounted: function () {
        this.initThree();
    }
}
</script>
   
<style lang="scss" scoped>
.chart-wraper {
    background-color: rgba($color: #2e62f1, $alpha: 0.7);
}
</style>