<template>
  <div>

    <!-- <div class="main">
      <div class="date">&nbsp;{{ weatherData.date }}&nbsp;&nbsp;&nbsp;农历{{ weatherData.nongli[1] }}{{ weatherData.nongli[2] }}&nbsp;&nbsp;&nbsp;{{ week }}
      </div>
      <div class="item">
        <div class="date"><span class="lable">天气：</span>{{ weatherData.text }}</div>
        <div class="date"><span class="lable">气温：</span>{{ weatherData.temp }}℃</div>
        <div class="date"><span class="lable">体感温度：</span>{{ weatherData.feelsLike }}℃</div>
        
      </div>
      <div class="item">
        <div class="date"><span class="lable">湿度：</span>{{ weatherData.humidity  }}%</div>
        <div class="date"><span class="lable">气压：</span>{{ weatherData.pressure   }}hpa</div>
        <div class="date"><span class="lable">湿度：</span>{{ weatherData.vis   }}KM</div>
      </div>
      <div>实时天气：{{weatherData.text }} <img :src="'./assets/images/'+weatherData.text+'.png'" alt="" class="weather-icon"></div>
      <div class="item">
        <div class="date"><span class="lable">风向：</span>{{ weatherData.windDir }}</div>
        <div class="date"><span class="lable">风速：</span>{{ weatherData.windSpeed }}&nbsp;m/s</div>
        <div class="date"><span class="lable">风力：</span>{{ weatherData.windScale  }}级</div>
      </div>
    </div> -->
    <div class="main">
      
      <div class="item">
        <div class="date"><span class="lable">梁总量</span>{{ weatherData.total }}</div>
        <div class="date"><span class="lable">已制梁</span>{{ weatherData.done }}</div>
      </div>
      <div class="item">
        <div class="date"><span class="lable">已架设</span>{{ weatherData.used  }}</div>
        <div class="date"><span class="lable">正在制梁</span>{{ weatherData.doing   }}</div>
      </div>
      <!-- <div>实时天气：{{weatherData.text }} <img :src="'./assets/images/'+weatherData.text+'.png'" alt="" class="weather-icon"></div> -->
      <div class="item">
        <div class="date"><span class="lable">正在存梁</span>{{ weatherData.saving }}</div>
        <div class="date"><span class="lable">未制梁</span>{{ weatherData.nodo  }}</div>
      </div>
    </div>
  </div>
</template>
  
<script>
import request from '@/api/request'
export default {
  name: 'Weather',
  data() {
    return {
      weatherData: '',
      week: ''
    }
  },
  mounted() {
    let _this = this
    request({ url: 'index', method: 'get' }).then(res => {
      _this.weatherData = res.data
    });
    setInterval(function(){
      request({ url: 'index', method: 'get' }).then(res => {
      _this.weatherData = res.data
    });
    },1000*60*5)
    this.DateFunc();
  },
  methods: {
   

    // 获取今天星期几
    DateFunc: function(){
      var tempDate = new Date();
      var days = tempDate.getDay();
      var week;

      switch (days) {
        case 1:
          week = '星期一';
          break;
        case 2:
          week = '星期二';
          break;
        case 3:
          week = '星期三';
          break;
        case 4:
          week = '星期四';
          break;
        case 5:
          week = '星期五';
          break;
        case 6:
          week = '星期六';
          break;
        case 0:
          week = '星期日';
          break;
      }
      
      this.week = week;
      console.log(week)
    }
  }
}
</script>
  
<style lang="scss" scoped>
.main {
  padding: 10px 20px;
  align-items: bottom;
  color: #fff;
  text-align: left;
  font-size: 20px;

  div {
    margin-top: 10px;
  }

  .item {
    display: flex;
    justify-content: space-around;
  }

  .date {
    text-align: center;
    color: #12f1ab;
    box-shadow: 0 0 10px #31a8ec;
    border: 2px solid #31a8ec;
    padding: 5px 10px ;
    width: 45%;
    background-color: rgba($color: #31a8ec, $alpha: 0.3);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    .lable {
      color: #fff;
      font-size: 16px;
    }
  }

  .weather-icon {
    width: 50px;
    vertical-align: middle;
  }
}</style>
  