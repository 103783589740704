<template>
    <div>
        <div class="chart-wraper">


            <div class="plan-detail">
                <div class="plan-total">
                    钢筋绑扎台座
                </div>
                <div class="plan-item">
                    <div class="graph">
                        <div ref="chartColumn1" class="charts"></div>
                    </div>
                    <div class="graph-title">
                        <div><span>已占用：</span><span>{{ chartData[0].complete_num}}</span></div>
                        <div><span>空闲：</span><span>{{ chartData[0].plan_num}}</span></div>
                    </div>

                </div>
                <div class="plan-total">
                    制梁台座
                </div>
                <div class="plan-item">
                    <div class="graph">
                        <div ref="chartColumn2" class="charts"></div>
                    </div>
                    <div class="graph-title">
                        <div><span>已占用：</span><span>{{ chartData[1].complete_num}}</span></div>
                        <div><span>空闲：</span><span>{{ chartData[1].plan_num}}</span></div>
                    </div>
                </div>
                <div class="plan-total">
                    存梁台座
                </div>
                <div class="plan-item">
                    <div class="graph">
                        <div ref="chartColumn3" class="charts"></div>
                    </div>
                    <div class="graph-title">
                        <div><span>已占用：</span><span>{{ chartData[2].complete_num}}</span></div>
                        <div><span>空闲：</span><span>{{ chartData[2].plan_num}}</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
   
<script>
import request from '@/api/request'
import "echarts-liquidfill/src/liquidFill.js";
export default {

    data() {
        return {
            total: '',
            plan1: '',
            plan2: '',
            chartData:[{},{},{}],
            
            option: {

                series: [
                    {
                        type: 'gauge',
                        center: ['50%', '50%'],
                        startAngle: 200,
                        endAngle: -20,
                        min: 0,
                        max: 465,
                        splitNumber: 10,
                        itemStyle: {
                            color: '#eba362'
                        },

                        pointer: {
                            show: true,
                            icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                            length: '12%',
                            width: 20,
                            offsetCenter: [0, '-60%'],
                            itemStyle: {
                                color: 'auto'
                            }
                        },
                        progress: {
                            show: false,
                            roundCap: true,
                            itemStyle: {
                                color: '#cb4c7d'
                            }
                        },
                        axisLine: {
                            roundCap: true,
                            lineStyle: {
                                width: 10,
                                color: [
                                    [0.33, '#ffd100'], // 0~10% 红轴
                                    [0.66, '#05eacb'], // 10~20% 绿轴
                                    [1, '#ff5553'], // 20~30% 蓝轴
                                    // ...
                                ]
                            }
                        },

                        axisTick: {
                            show: true,
                            distance: -5,
                            splitNumber: 5,
                            lineStyle: {
                                width: 1,
                                color: '#fff'
                            }
                        },
                        splitLine: {
                            show: true,
                            distance: -10,
                            length: 8,
                            lineStyle: {
                                width: 1,
                                color: '#fff'
                            }
                        },
                        axisLabel: {
                            show: false,
                            distance: -10,
                            color: '#fff',
                            fontSize: 8,
                            rotate: 8
                        },
                        anchor: {
                            show: false
                        },
                        title: {
                            offsetCenter: [0, '20%'],
                            fontSize: 12,
                            color: '#fff',
                            fontWeight: 'bold'
                        },
                        detail: {
                            valueAnimation: true,
                            width: '60%',
                            lineHeight: 40,
                            borderRadius: 8,
                            offsetCenter: [0, '-20%'],
                            fontSize: 15,
                            fontWeight: 'bolder',
                            formatter: '{value}榀',
                            color: '#07ecfe'
                        },
                        data: [
                            {
                                value: 356,
                                name: '32米箱梁'
                            }
                        ]
                    }
                ]
            }
        }
    },
    methods: {
        echart2_1: function (val) {
            var myChart = this.$echarts.init(this.$refs.chartColumn1);
            var option = {
                series: [
                    {
                        type: "liquidFill",
                        data: [val],
                        radius: "70%",
                        color: [
                            'rgba(0,255,255,0.8)'
                        ],
                        itemStyle: {
                            opacity: 1,
                        },
                        label: {
                            show: true,
                            textStyle: {
                                fontSize: 20
                            }
                        },

                        outline: {
                            itemStyle: {
                                borderColor: "#00ffff",
                                borderWidth: "3",
                            },
                            borderDistance: 10,
                        },
                        backgroundStyle: {
                            borderWidth: 2,
                            borderColor: "#00ffff",
                            color: "#07164d",
                        },
                    },
                ],
            };
            myChart.setOption(option);
            window.addEventListener("resize", function () {
                myChart.resize();
            });
        },
        echart2_2: function (val) {
            var myChart = this.$echarts.init(this.$refs.chartColumn2);
            var option = {
                series: [
                    {
                        type: "liquidFill",
                        data: [val],
                        radius: "70%",
                        color: [
                            'rgba(0,255,255,0.8)'
                        ],
                        itemStyle: {
                            opacity: 1,
                        },
                        label: {
                            show: true,
                            textStyle: {
                                fontSize: 20
                            }
                        },

                        outline: {
                            itemStyle: {
                                borderColor: "#00ffff",
                                borderWidth: "3",
                            },
                            borderDistance: 10,
                        },
                        backgroundStyle: {
                            borderWidth: 2,
                            borderColor: "#00ffff",
                            color: "#07164d",
                        },
                    },
                ],
            };
            myChart.setOption(option);
            window.addEventListener("resize", function () {
                myChart.resize();
            });
        },
        echart2_3: function (val) {
            var myChart = this.$echarts.init(this.$refs.chartColumn3);
            var option = {
                series: [
                    {
                        type: "liquidFill",
                        data: [val],
                        radius: "70%",
                        color: [
                            'rgba(0,255,255,0.8)'
                        ],
                        itemStyle: {
                            opacity: 1,
                        },
                        label: {
                            show: true,
                            textStyle: {
                                fontSize: 20
                            }
                        },

                        outline: {
                            itemStyle: {
                                borderColor: "#00ffff",
                                borderWidth: "3",
                            },
                            borderDistance: 10,
                        },
                        backgroundStyle: {
                            borderWidth: 2,
                            borderColor: "#00ffff",
                            color: "#07164d",
                        },
                    },
                ],
            };
            myChart.setOption(option);
            window.addEventListener("resize", function () {
                myChart.resize();
            });
        },
        initChart(data) {
            this.chartData[0]=data[0]
            this.chartData[1]=data[1]
            this.chartData[2]=data[2]
            this.echart2_1(data[0].rate)
            this.echart2_2(data[1].rate)
            this.echart2_3(data[2].rate)
        }
    },
    mounted: function () {
        let _this = this
        
        request({ url: 'plan', method: 'get' }).then(res => {
            _this.chartData = res.data
            _this.initChart(res.data)
        });
       
    }
}
</script>
   
<style lang="scss" scoped>
.title {
    color: #fff;
    line-height: 25px;
    height: 25px;
    text-align: left;
    text-shadow: 0 0 10px #31a8ec;

    &:before {
        content: '';
        margin-right: 5px;
        vertical-align: middle;
        display: inline-block;
        border-radius: 5px;
        width: 10px;
        height: 25px;
        background-color: #11dbee;
    }
}
.charts{
    width: 100%;
    height: 130px;
}
.chart-wraper {
    padding: 10px;
    padding-top: 20px;
    box-sizing: border-box;

    .plan-total {
        color: #fff;
        text-shadow: 0 0 10px #31a8ec;

        &:before {
            content: '';
            margin-right: 5px;
            vertical-align: middle;
            display: inline-block;
            border-radius: 5px;
            width: 10px;
            height: 10px;
            background-color: #11dbee;
        }

        &::after {
            content: '';
            margin-right: 5px;
            vertical-align: middle;
            display: inline-block;
            border-radius: 5px;
            width: 10px;
            height: 10px;
            background-color: #11dbee;
        }
    }

    .plan-detail {
        color: #fff;
        line-height: 1.5em;

        .plan-item {
            display: flex;
            text-align: center;
            align-items: center;

            .graph {
                width: 60%;
            }

            .graph-title {
                text-align: left;
                width: 25%;

                div {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                }
            }
        }
    }

}</style>