<template>
  <div class="body">

    <div class="main">
      <Material />
      <Temperature />
    </div>
    <div class="plan">
      <Plan/>
    </div>
  </div>
</template>
  
<script>
// @ is an alias to /src
import Temperature from './components/Temperature.vue';
import Material from './components/Material.vue';
import Plan from './components/Plan.vue';
export default {
  name: 'Charts',
  components: { Temperature, Material,Plan}
}
</script>
  
<style lang="scss" scoped>
.plan{
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.main {
  display: flex;
  padding-left: 20px;
  align-items: bottom;
}
</style>
  