import axios from "axios";
import store from "../store";
switch (process.env.NODE_ENV) {
  case "development":
    axios.defaults.baseURL = "http://daping.vm/index.php/api/index/";
    break;
  case "production":
    axios.defaults.baseURL = "/index.php/api/index/";
    break;
  default:
    break;
}
/**
 * @请求头设置
 *
 * headers 请求头 application/json(以json格式发给后端) application/x-www-form-urlencoded(以普通表单形式发给后端)
 * timeout 超时时间 10s
 *
 */
//axios.defaults.headers.post["Content-Type"] = "application/json"; // 请求头 application/x-www-form-urlencoded(以普通表单形式发给后端)
const service = axios.create({
  headers: { "Content-Type": "application/json" }, // 请求头 application/json(以json格式发给后端) application/x-www-form-urlencoded(以普通表单形式发给后端)
  timeout: 60000
});
/**
 * @请求拦截
 * 发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
 * 可以携带token传入
 *
 */

service.interceptors.request.use(
  config => {
    // config.headers.Authorization = 'eyJhbGciOiJSUzI1NiJ9.eyJjdXN0b21lcklkIjoiWTIwMjMwNjEzMTQwNzE0IiwibG9naW4tdXNlci11dWlkLWtleSI6IjI2ZjMxYTNhLTRkMGUtNGE1ZS04ODExLTI1Yzg5Njg2MWMzMCIsInRlbGVwaG9uZSI6IjE1MDAyNDkxNjU1IiwiaXNzIjoiY21zLnRva2VuLmlzLnVzZXIiLCJzdWIiOiJjbXMudG9rZW4uc3ViamVjdCIsIm5iZiI6MTY4ODU3MjkwMywiaWF0IjoxNjg4NTcyOTA4LCJleHAiOjE2ODg1ODAxMDh9.KfGBQY9D-D72te1sQCfxubeYPRbtgH12m3Kl8W6DUrCoB55U2vGQ18bs4APigkJyOvt-2zuNMO0nA3PJyUf-1ciAVMmytPiW9JPQK7edoz8dmYVUthyZB2Upny2M7YqxmpnKcqiTyJkUWheDMgd48qLZJd6genNyLMNCKRsR5FZISX3t8CFy8zLk1h-v-FFe90xYCSjC-nKCEI1Yy4-JyKV8VWR1wC_NxG9yUifSaAR1LYDVY7iqw5fIn3o6hGtNRn-we4Z2IDkwQh_dH54YH3sTVxMg8tffRpDwk2mEEMlExnpFoNKoh5S-J1UG3OijZhD4teSYcnf8G1RV-7O5MQ'
    config.data = JSON.stringify(config.data); //数据转化,JSON格式

    var list = localStorage.getItem("token");
    if (list) {
      config.headers["Authorization"] = list; //如果要求携带在请求头中
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
/**
 * @响应拦截
 * 返回异常及处理方法
 *
 */
service.interceptors.response.use(
  response => {
    // 400048;
    if (response.data.code == 400048) {

      return;
    }
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    // console.log("拦截器", response);
    switch (response.status) {
      case 401:
        break;
      case 403:
        break;
      case 404:
        break;
      case 201:
        break;
      default:
        return response.data;
    }
  },
  error => {
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "错误请求";
          break;
        case 401:
          error.message = "登录已过期，请重新登录";
          window.localStorage.removeItem("userData");
          router.push({ path: "/login" });
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = "请求错误,未找到该资源";
          // window.location.href = "/index";
          break;
        case 405:
          error.message = "请求方法未允许";
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器端出错";
          break;
        case 501:
          error.message = "网络未实现";
          break;
        case 502:
          error.message = "网络错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网络超时";
          break;
        case 505:
          error.message = "http版本不支持该请求";
          break;
        default:
          error.message = `连接错误${error.response.status}`;
      }
    } else {
      // 超时处理
      if (error.message.includes("timeout")) {
        Dialog({ message: "服务器响应超时，请刷新页面" }).then(() => {
          location.reload();
        });
        return;
      }
      return;
    }
    return Promise.resolve(error.response);
  }
);

export default service;
